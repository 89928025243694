import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import { RELATIVE_PATH } from "../../constants/nl-config";
import { useStores } from "../../stores/RootStore";
import Button from "../button";
import RangeSlider from "../range-slider";
import styles from "./index.module.scss";
import NumberFormat from 'react-number-format';

const PurchaseNfts = observer(() => {
	const { t } = useTranslation();
	const { uiStore, etherStore } = useStores();
	const { setShowMyNfts, isCorrectWallet } = uiStore;
	const { connectToWallet, nftBalance, purchaseNft, walletAddress, sliderValue, walletBalance, soldNfts, maxNFTendos } = etherStore;

	return (
		<div name="purchase-nfts" id="purchase-nfts" className={styles["generator-container"]}>
			<div data-aos="zoom-in" className={styles["generator-wrapper"]}>
				<h2>{t("purchase_nfts.purchase_nftendo")}</h2>
				<div className={styles["row"]}>
					<div className={styles["column"]}>
						<div className={styles["generator-image-container"]}>
							<img alt={"PolyDoge Cards Minting"} src={"images/polydoge-cards-minting.gif"} />
						</div>
					</div>
					<div className={styles["column"]}>
						<div className={styles["generator"]}>
							<div className={styles["generator-title"]}>
								<div className={styles["sold"]}>{soldNfts} / {maxNFTendos} {t("purchase_nfts.sold")}</div>
							</div>
							{!walletAddress ?
								<></>
								:
								<div className={styles["balance"]}>{t("purchase_nfts.balance")}
									<NumberFormat
										value={parseFloat(walletBalance).toFixed(0)}
										displayType={'text'}
										thousandSeparator={true} />
									{' PolyDoge'}
								</div>
							}						
						</div>
					</div>
				</div>
			</div>
		</div>
	)
});

export default PurchaseNfts;
