import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import { DarkIcon } from "../../assets/img/darkIcon.js";
import { LightIcon } from "../../assets/img/lightIcon.js";
import ScrollLinkEnum from "../../enums/scroll-link-enum";
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const NavigationBar = observer(() => {
    const { t } = useTranslation();
    const { uiStore } = useStores();
    const { switchTheme, theme, hamburgerMenuOpen, switchHamburgerMenu, scrollLink, setScrollLink } = uiStore;

    return (
        <>
            <div className={styles["navbar-container"]}>
                <div className={styles["navbar"]}>
                    <div className={styles["logo-container"]}>
                        <NavLink to="/">
                            <img className={styles["logo"]} alt={"PolyDoge Cards logo"} src={"images/pd-cards-logo.png"} />
                        </NavLink >
                    </div>
                    <div className={styles["links-container"]}>
                        <div className={styles["links"]}>
                            <NavLink onClick={() => setScrollLink(ScrollLinkEnum.HOME)} exact={true} activeClassName={scrollLink === ScrollLinkEnum.HOME ? styles["active-link"] : null} to="/">{t("navbar.home")}</NavLink >
                            <NavLink onClick={() => setScrollLink(ScrollLinkEnum.DESCRIPTION)} activeClassName={scrollLink === ScrollLinkEnum.DESCRIPTION ? styles["active-link"] : null} to="/#polydoge-cards-description">{t("navbar.description")}</NavLink>
                            <NavLink onClick={() => setScrollLink(ScrollLinkEnum.BUY_NFTS)} activeClassName={scrollLink === ScrollLinkEnum.BUY_NFTS ? styles["active-link"] : null} to="/#purchase-nfts">{t("navbar.buy_nfts")}</NavLink>
                        </div>
                        <div className={styles["theme-wrapper"]}>
                            <div className={styles["hamburger-menu"]} onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); }}>
                                {t("navbar.menu")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles["dark-light-theme"]} onClick={() => { switchTheme(); }}>
                    <LightIcon theme={theme} /><DarkIcon theme={theme} />
                </div>
                <a href="https://t.me/polydoge">
                    <img alt="Telegram icon" className={styles["social-icons"]} src={"images/telegram.svg"} />
                </a>
                <a href="https://discord.link/PolyDoge">
                    <img alt="Discord icon" className={styles["social-icons"]} src={"images/discord.svg"} />
                </a>
                <a href="https://twitter.com/polydoge">
                    <img alt="Twitter icon" className={styles["social-icons"]} src={"images/twitter.svg"} />
                </a>
            </div>
            {hamburgerMenuOpen ?
                <div className={styles["hamburger-menu-dropdown"]}>
                    <div className={styles["responsive-links-container"]}>
                        <NavLink onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); setScrollLink(ScrollLinkEnum.HOME) }} exact={true} activeClassName={scrollLink === ScrollLinkEnum.HOME ? styles["active-link"] : null} to="/">{t("navbar.home")}</NavLink >
                        <NavLink onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); setScrollLink(ScrollLinkEnum.DESCRIPTION); }} activeClassName={scrollLink === ScrollLinkEnum.DESCRIPTION ? styles["active-link"] : null} to="/#polydoge-cards-description">{t("navbar.description")}</NavLink>
                        <NavLink onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); setScrollLink(ScrollLinkEnum.BUY_NFTS); }} activeClassName={scrollLink === ScrollLinkEnum.BUY_NFTS ? styles["active-link"] : null} to="/#purchase-nfts">{t("navbar.buy_nfts")}</NavLink>
                        <div className={styles["social-mobile-container"]}>
                            <a href="https://t.me/polydoge">
                                <img alt="Telegram icon" className={styles["social-icons-mobile"]} src={"images/telegram.svg"} />
                            </a>
                            <a href="https://discord.link/PolyDoge">
                                <img alt="Discord icon" className={styles["social-icons-mobile"]} src={"images/discord.svg"} />
                            </a>
                            <a href="https://twitter.com/polydoge">
                                <img alt="Twitter icon" className={styles["social-icons-mobile"]} src={"images/twitter.svg"} />
                            </a>
                        </div>
                        <div className={styles["dark-light-theme-mobile-wrapper"]}>
                            <div className={styles["dark-light-theme-mobile"]} onClick={() => { switchTheme(); }}>
                                <LightIcon theme={theme} /><DarkIcon theme={theme} />
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </>
    )
});

export default NavigationBar;
