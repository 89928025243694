import { observer } from "mobx-react";
import React from 'react';
import styles from "./index.module.scss";

const Spinner = observer(() => {
    return (
        <div className={styles["spinner-container"]}>
            <img alt={"Spinner"} className={styles["spinner-image"]} src={"images/polydoge-website-logo.png"} />
        </div>
    )
});

export default Spinner;