export const TRANSLATIONS_EN = {
    navbar: {
        home: "HOME",
        description: "ABOUT",
        buy_nfts: "PURCHASE",
        faq: "FAQ",
        browse_nftendos: "BROWSE",
        menu: "MENU",
    },
    header: {
        title1: "PolyDoge Cards",
        title2: "Burn. Mint. Trade.",
        description1: "You have seen punks fly off the shelves, apes enjoying the yachting lifestyle and by now, it seems the copycats are busy rando-generating the entire animal kingdom.",
        description2: "We could have just joined in on this gold rush, but that is not how we roll here in the PolyDoge fam. Instead, we decided to do what we do best: create it first, then burn it all down to the ground in a glorious inferno!",
        tv_text: ">>> CLICK ME <<<"
    },
    nft_description: {
        reward_title1: "Igniting your Collector’s fever",
        reward_description1: "PolyDoge Cards are 10,000 unique NFTs varying in rarity, attributes and design. They are randomly generated from a pool of hand-made designs crafted by our genius in-house artists. Collecting and trading them is just part of the fun. There will be utility and additional perks added to the cards for holders, no pun intended. Oh, and did we mention that ALL of the proceeds – no ifs, ands or buts - will be buuuuuurrrned?",
        reward_title2: "We have created the classic win-win-win situation",
        reward_description2: "Your participation in this festival of FOMO will not only contribute to PolyDoge deflation, but create an entirely new asset with a capped circulation in the process. There is less than half a card per current PolyDoge holder currently up for grabs. Last but not least, this is all wrapped in a nice package of dopamine-dumping, randomized minting fun and will help PolyDoge to assert even more dominance when it comes to providing value, utility and entertainment for our community.",
        reward_description3: "The time is soonTM. Honour the legacy of our dev’s final sacrifice, burn some of your PolyDoge at the altar of deflation and see what the RNG gods reward you with in return.",
        purchase_title: "Here is how it works:",
        purchase_description_1: "The easiest way to acquire your very own NFTendo is to purchase it below. You can also trade them on any NFT marketplaces supporting NFTendos. The purchase price follows a bonding curve which rewards early supporters, and 75% of the NFTs cost less than 0.5 BNB while the rest cost a maximum of 0.7 BNB.",
        purchase_description_1_prices:
            [
                "1.  Connect your Metamask wallet",
                "2.  Select the number of PolyDoge Cards you want to mint with the slider (Maximum 5 per transaction!)",
                "3.  Burn some PolyDoge and forge your unique cards in the flames",
                "4.  Collect, hold or trade them to get all your favorite combinations",
                "5.  Look forward to additional perks and utility #soon™"
            ],
        purchase_description_2: "To initiate the purchase you must first connect a wallet which has been topped up with PolyDoge.",
        purchase_description_3: "Once you have completed the purchase, the NFT is automatically sent to your wallet.",
        interacting_title: "",
        interacting_description: "",
    },
    purchase_nfts: {
        purchase_nftendo: "Purchase PolyDoge Cards",
        sold: "SOLD",
        balance: "Balance: ",
        connect_to_wallet: "CONNECT TO WALLET",
        purchase_nftendos: "PURCHASE NFTENDOS",
        wrong_chain_id: "CONNECTED TO WRONG CHAIN",
        accept_tos: "YOU MUST ACCEPT OUR TOS",
            disclaimer: "By purchasing PolyDoge Cards you agree that you are only purchasing an NFT. Any potential community rewards are dependant on the total supply of PolyDoge Cards having been sold out within 6 months from the start of sale. In the event that the total supply of PolyDoge Cards have not been sold out within 6 months from start of sale, no community rewards will be given out."
    },
    range_slider: {
        title: "NFTs to purchase",
        total_cost: "Total : "
    },
    nft_search_bar: {
        find_nftendos: "Find NFTendos:",
        placeholder: "IDs seperated with comma e.g.: 1,2,3",
        show_nfts: "SHOW MY NFTS",
        search_nfts: "SEARCH NFTS"
    },
    faq: {
        title: "FREQUENTLY ASKED QUESTIONS",
        items: [
            { question: "Is the community reward system fully randomized?", answer: "Yes. We have worked with Chainlink and their VRF solution which provides tamper-proof randomness that cannot be manipulated by any user, node operator, or malicious actor compared to for example using block.time which could be manipulated: https://chain.link/solutions/chainlink-vrf" },
            { question: "What is the provance record for NFTendo?", answer: "Each NFTendo has been hashed using SHA-256, and all hashes are then combined into one long string which is again hashed using SHA-256 and stored on the blockchain inside the NFTendo smart contract." },
            { question: "Will the value of my NFTendos increase?", answer: "NFTendos are NFTs designed as collectible trading cards on the Binance Smart Chain. Just as any other piece of art, the price fully depends what people are willing to pay, and the cards you value the highest may for others have zero value. Just as buying any piece of art, you can't know if the price will stay stable, increase of have a value of zero." },
            { question: "Can I, in advance, know which NFTendo I will get?", answer: "Before a NFTendo has been minted, all information except for the SHA-256 hash belonging to the image is unrevealed. After a purchase has been made, the NFTendo repository is automatically updated with the corresponding image and stats with a few minutes." },
            { question: "What happens if not all 10000 NFTendos have been sold within 6 months?", answer: "In the event that not all NFTendos have been sold within 6 months, the community reward is cancelled and all funds collected goes to the team." },
            { question: "Will NFTendo not get anything if all 10000 NFTendos have been sold within 6 months?", answer: "Once the community reward pool (2000 BNB) has been filled up, NFTendo can withdraw any excess funds. As an example, if there is 2100 BNB on the contract then NFTendo are able to withdraw a maximum of 100 BNB during the first 6 months. If there is 1900 BNB, NFTendo cannot withdraw any funds from the contract during the first 6 months. Should not all 10000 NFTendos have been sold within 6 months, then the community reward system has been unsuccessful which means no community rewards are paid out and NFTendo can withdraw all funds currently in the contract." },
            { question: "How do I interact with my NFTendos?", answer: "You can view your NFTendos on this website and on any wallet with support, and they are tradable on open NFT Marketplaces supporting the BEP721 standard." },
            { question: "I won! How do I claim my community reward?", answer: "You can claim your community reward on the website within 6 months from start of sale, if the community reward program was successful. To collect your community reward, input the slot number and press the Claim Reward button. To successfully claim, the winner address and the address you are transacting from must match." },
            { question: "What happens if there are unclaimed community rewards after six months from start of sale?", answer: "Any unclaimed funds left on the contract 6 months from the start of sale will also belong to the team." },
            { question: "What is the code?", answer: "Up, Up, Down, Down, Left, Right, Left, Right, B, A." }



        ]
    }
};
