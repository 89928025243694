import AOS from 'aos';
import 'aos/dist/aos.css';
import { observer } from "mobx-react";
import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from "react-router-dom";
import './App.css';
import Backdrop from "./components/backdrop";
import Modal from './components/modal';
import NavigationBar from "./components/navbar";
import Notification from "./components/notification";
import Spinner from './components/spinner';
import { RELATIVE_PATH } from "./constants/nl-config";
import { useStores } from "./stores/RootStore";
import "./styles/theme.scss";
import "./translations/i18n";
import About from "./views/about";
import Home from "./views/home";

const App = observer(() => {
  const location = useLocation();
  const { uiStore } = useStores();
  const { theme, setTheme, isLoading, background, setBackground } = uiStore;

  useEffect(() => {
    if (!theme) {
      localStorage.setItem("theme", "dark");
      setTheme("dark");
    }
    if (theme === "dark") {
      setBackground("url(/images/polydoge-background.jpg)");
    } else {
      setBackground("url(/images/polydoge-background-light.jpg)");
    }
  })

  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true
    });
  }, []);


  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" })
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
  }, [location])


  return (
    <div className={`App ${theme}`} style={{ backgroundImage: background }}>
      {isLoading && <Spinner />}
      <Notification />
      <NavigationBar />
      <Modal />
      <Backdrop />
      <Switch>
        <Route exact path={`${RELATIVE_PATH}/`} component={Home} />
        <Route path={`${RELATIVE_PATH}/about`} component={About} />
      </Switch>
    </div >
  );
})

export default App;