import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from "../../components/button";
import styles from "./index.module.scss";

const Header = observer(() => {
    const { t } = useTranslation();

    return (
        <div id="header" className={styles["header-container"]}>
            <div data-aos="zoom-in">
                <h2 className={styles["title-1"]}>{t("header.title1")}</h2>
                <div className={styles["title-2"]}>{t("header.title2")}</div>
                <div className={styles["row"]}>
                    <div className={styles["column"]}>
                        <div className={styles["hero-image-container"]}>
                            {
                                <div className={styles["flip-card"]}>
                                    <div className={styles["flip-card-inner"]}>
                                        <div className={styles["flip-card-front"]}>
                                            <img className={styles["hero-image"]} alt={"PolyDoge Cards Hero"} src={"pd-cards-bottom-hero.jpg"} />
                                        </div>
                                        <div className={styles["flip-card-back"]}>
                                            <div className={styles["card-back-text"]}>{'CODE: '}</div>
                                            <div className={styles["card-back-text"]}>{'UP UP DOWN DOWN LEFT RIGHT LEFT RIGHT B A'}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={styles["column"]}>
                        <div className={styles["header-text-container"]} name="tv-layer">
                            <div>
                                <div >
                                    <div>
                                        <p>{t("header.description1")}</p>
                                        <br></br>
                                        <p>{t("header.description2")}</p>
                                    </div>
                                </div>
                            </div>
                            <Link className={styles["button"]} to="/#purchase-nfts">
                                <Button label={"BUY NOW"} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default Header;
